import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import MonthlyView from '../views/MonthlyView.vue'
import CategoryView from '../views/CategoryView.vue'

import UserService from '../services/UserService'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/monthly',
    name: 'monthly',
    component: MonthlyView,
    props: {
      list_type: 'monthly'
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bycategory',
    name: 'bycategory',
    component: CategoryView,
    props: {
      list_type: 'category'
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'login',
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, _from, next) => {
  console.log('Router before each')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    UserService.isAuthorized().then((_authed) => {
      console.log('isauthed', _authed)
      if (_authed === true) {
        if (to.matched.some(record => record.meta.adminOnly)) {
          if (UserService.isAdmin()) {
            next()
          } else {
            next(false)
          }
        } else {
          next()
        }
      } else {
        next({
          path: '/login'
        })
      }
    })
  } else {
    UserService.isAuthorized().then((_authed) => {
      console.log('else is authorized', _authed)
      if (_authed === true) {
        next({
          path: '/home'
        })
      } else {
        next()
      }
    })
  }
})

export default router
